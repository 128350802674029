<template lang='pug'>
.page.share(ref='poster')
  .page_bg_img(ref='bgimg')
  .logo(ref='logo')

  .share_card
    .user
      .head_img(ref='headimg')
      .r
        .name {{ userInfo.nickName }}
        .txt
          span 邀你
          | 一起参与理性测评2.0

    .designation_top_line
      .tag 获得称号
      .info
        .gary 总分
          span {{ score }}
          i 分
        .gary 超过
          span {{ proportion }}%
          i 用户

    .designation {{ info.title }}
    .designation_info {{ info.content }}

    .dotted_line
      .circle.l
      .circle.r

    .qrcode
      .l(ref='qrcode')
      .r
        .img(ref='fingerprint')
        .txt 长按识别二维码
        .txt.color 开始测评

    .circles
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
      .circle
</template>

<script>
import html2canvas from 'html2canvas'
import { getQueryString } from '../assets/js/utils'

export default {
  name: 'Share',
  computed: {
    comments() {
      return this.$store.state.comments
    },
    proportion() {
      return (this.$store.state.fraction && this.$store.state.fraction.proportion) || 0
    },
    score() {
      return (this.$store.state.fraction && this.$store.state.fraction.score) || 0
    },
    lv() {
      const score = this.$store.state.fraction && this.$store.state.fraction.score
      if (!score || score <= 34) {
        return 1
      } else if (score <= 59) {
        return 2
      } else if (score <= 68) {
        return 3
      } else if (score <= 76) {
        return 4
      } else if (score <= 83) {
        return 5
      } else if (score <= 89) {
        return 6
      } else if (score <= 94) {
        return 7
      } else if (score <= 97) {
        return 8
      } else if (score <= 99) {
        return 9
      } else {
        return 10
      }
    },
    info() {
      return this.comments[`c${this.lv}`]
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  methods: {
    async creatPoster() {
      this.$loading.show()
      // 生成海报
      const loadImages = (src, element) => {
        return new Promise((resolve, reject) => {
          const img = document.createElement('img')
          img.src = src
          img.setAttribute('crossOrigin', 'anonymous')
          img.style = 'width:100%'
          element.innerHTML = ''
          element.appendChild(img)
          img.onload = () => {
            resolve('ok')
          }
          img.onerror = (e) => {
            reject(e)
          }
        })
      }

      await Promise.all([
        loadImages(require(`../assets/img/lv_bg_0${this.lv}.png`), this.$refs.bgimg),
        loadImages(require(`../assets/img/logo_white.png`), this.$refs.logo),
        loadImages(this.userInfo.avatarUrl, this.$refs.headimg),
        loadImages(require(`../assets/img/url_qrcode.png`), this.$refs.qrcode),
        loadImages(require(`../assets/img/fingerprint.png`), this.$refs.fingerprint),
      ])

      window.pageYOffset = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0

      const creatImg = async () => {
        try {
          const canvas = await html2canvas(this.$refs.poster, {
            useCORS: true,
            backgroundColor: null,
          })
          const img = document.createElement('img')
          img.src = canvas.toDataURL('image/png', 1)
          img.style = 'width:100%;position:absolute;left:0;top:0;z-index:5;'
          this.$refs.poster.appendChild(img)

          const tip = document.createElement('div')
          tip.innerText = '长按图片保存并分享'
          tip.style = 'width:100%;position:absolute;left:0;bottom:.3rem;font-size:.3rem; color:#fff;pointer-events: none;font-weight:bold;z-index:6;text-align:center;text-shadow: 0 1px 1px #666;'
          this.$refs.poster.appendChild(tip)
        } catch (e) {}
        this.$loading.hide()
      }
      setTimeout(() => {
        creatImg()
      }, 300)
    },
  },
  async created() {
    try {
      await this.$store.dispatch('getFraction')
    } catch (e) {
      this.$router.push('/question')
      return
    }

    this.creatPoster()

    const ready = () => {
      this.$regWeChat()
      const code = getQueryString('code')
      if ((!this.$store.state.userInfo.openID || !this.$store.state.userToken.accessToken) && !code) {
        window.location.href.indexOf('/login') < 0 && this.$setExpireStore('fromPath', window.location.href)
        const url = process.env.VUE_APP_API_URL
        window.location.replace(`${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_userinfo&redirectURI=${encodeURIComponent(window.location.href)}`)
      }
    }

    if (this.$store.state.isWeixin && (!window.WeixinJSBridge || !WeixinJSBridge.invoke)) {
      document.addEventListener('WeixinJSBridgeReady', ready, false)
    } else {
      ready()
    }
  },
}
</script>

<style lang='stylus'>
.share
  background: #0563DA
  background-size: cover
  padding-top: 0.3rem
  padding-bottom: calc(constant(safe-area-inset-bottom) / 2 + 0.9rem)
  padding-bottom: calc(env(safe-area-inset-bottom) / 2 + 0.9rem)

  .page_bg_img
    position: absolute
    top: 0
    left: 0
    z-index: 1
    width: 7.5rem
    height: auto

    img
      width: 100%

  .logo
    width: 2.22rem
    height: 0.59rem
    margin: 0 0.3rem
    position: relative
    z-index: 5

    img
      width: 100%
      height: 100%

  .share_card
    width: 6.9rem
    margin: 1.7rem auto 0
    background: #fff
    border-radius: 0.24rem 0.24rem 0 0
    position: relative
    padding: 0.4rem 0.32rem 0.6rem
    box-sizing: border-box
    position: relative
    z-index: 3

    .user
      display: flex
      flex-flow: row nowrap
      align-items: center

      .head_img
        width: 0.9rem
        height: 0.9rem
        overflow: hidden
        background: #eee
        border-radius: 50%

        img
          width: 100%
          height: 100%

      .r
        margin-left: 0.2rem

        .name
          font-size: 0.36rem
          color: #333333
          line-height: 1

        .txt
          font-size: 0.24rem
          color: #BABABA
          line-height: 1
          margin-top: 0.15rem

          span
            color: #DC5B10
            display: inline-block
            padding: 0 0.1rem
            border-left: 0.04rem solid #DC5B10
            border-right: 0.04rem solid #DC5B10
            margin-right: 0.1rem

    .designation_top_line
      display: flex
      flex-flow: row no-wrap
      align-items: center
      justify-content: space-between
      margin-top: 0.5rem

      .tag
        background: #1068DC
        border-radius: 0.04rem 0.04rem 0.33rem 0.04rem
        padding: 0.1rem 0.2rem
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        font-size: 0.24rem

      .info
        flex: 1
        font-size: 0.24rem
        color: #D3D3D3
        line-height: 1
        margin-top: 0.3rem
        display: flex
        align-items: center
        justify-content: flex-end

        .gary
          color: #D3D3D3
          display: inline-block
          margin-left: 0.4rem

          span
            color: #0B73F7
            font-size: 0.48rem
            padding: 0 0.06rem

          i
            color: #333

    .designation
      font-size: 0.54rem
      color: #494949
      letter-spacing: 0.03rem
      text-align: center
      font-weight: bold
      padding: 0.1rem
      margin-top: 0.3rem
      position: relative

      &:before, &:after
        display: block
        content: ' '
        width: 0.15rem
        height: 0.2rem
        border: 0.1rem solid #0563DA
        position: absolute

      &:before
        left: 0
        top: 0
        border-right-color: transparent
        border-bottom-color: transparent

      &:after
        right: 0
        bottom: 0
        border-left-color: transparent
        border-top-color: transparent

    .designation_info
      margin-top: 0.3rem
      font-size: 0.24rem
      color: #999999
      line-height: 0.4rem

    .dotted_line
      margin-top: 0.4rem
      border-top: 0.05rem dotted #E6E6E6
      position: relative
      width: 100%
      height: 0

      .circle
        width: 0.5rem
        height: 0.5rem
        background: #0563DA
        border-radius: 0.3rem
        position: absolute
        top: -0.3rem

        &.l
          left: -0.6rem

        &.r
          right: -0.6rem

    .qrcode
      display: flex
      align-items: center
      justify-content: center
      padding: 0.5rem 0 0.3rem

      .l
        width: 1.9rem
        height: 1.9rem
        background: #eee
        border: 1px solid #eee

        img
          width: 100%
          height: 100%

      .r
        margin-left: 0.4rem

        .img
          width: 0.78rem
          height: 0.82rem

          img
            width: 100%
            height: 100%

        .txt
          font-size: 0.28rem
          color: #D8D8D8
          margin-top: 0.2rem
          line-height: 1

          &.color
            color: #DC5B10

    .circles
      position: relative
      height: 0
      margin: 0 -0.3rem
      display: flex
      align-items: center
      justify-content: space-around

      .circle
        width: 0.4rem
        height: 0.4rem
        background: #0563DA
        border-radius: 0.2rem
        position: relative
        top: 0.6rem
</style>
